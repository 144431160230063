import { Button, Card, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export default function HomeCard(){
    const history = useHistory();
    const data = {
        image1: 'https://media.istockphoto.com/photos/two-glass-of-whiskey-with-ice-picture-id1095608490?k=20&m=1095608490&s=612x612&w=0&h=MWHRUhOO1joew-8rWxSXeLg3oHdjk_HSKFm83ELldBc=',
        image2: 'https://images.pexels.com/photos/6475040/pexels-photo-6475040.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        image3: 'https://images.pexels.com/photos/2566029/pexels-photo-2566029.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
        image4: 'https://images.pexels.com/photos/7254144/pexels-photo-7254144.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        image5: 'https://images.pexels.com/photos/96420/pexels-photo-96420.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        image6: 'https://images.pexels.com/photos/2416463/pexels-photo-2416463.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        image7: 'https://images.pexels.com/photos/6752422/pexels-photo-6752422.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    }
    return (
        <div className='homePage'>
            <Carousel className='homePageCarousel'>
                <Carousel.Item className='carousel-item'>
                    <img id="carousel-img" className="d-block w-100" src={data.image1} alt="First slide"/>
                    <Carousel.Caption><h1>World-class Choices</h1></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img id="carousel-img" className="d-block w-100" src={data.image2} alt="Second slide"/>
                    <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img id="carousel-img" className="d-block w-100" src={data.image3} alt="Third slide" />
                    <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <Card className="homePageCard bg-dark text-white">
                <Card.Img id="image" src={data.image4} alt="Card" />
                <Card.ImgOverlay className='storeDetails'>
                    <h1>Whiskey Room</h1>
                    <Card.Text>
                    Whiskey Room is the best place to satisfy your unique alcohol fix. Providing world-class and well-known brands and products, we're sure you won't look for another provider once you see what's in our room. Aside from our top-notch selection, we are also available for consultation.
                    </Card.Text>
                    <Card.Text>By getting to know our clients, we can suggest and guarantee (Yes! That's right!) what would be the next best purchase for them.</Card.Text>
                </Card.ImgOverlay>
            </Card>
            <div className='homeCategory'>
                <Card className="home-entry bg-dark text-white">
                    <Link onClick={()=>history.push('/collection/japan')}>
                    <Card.Img className='home-entry-img' src={data.image5} alt="Card image" />
                    <Card.ImgOverlay className='storeDetails'>Japanese
                    </Card.ImgOverlay>
                    </Link>
                </Card>
                <Card className="home-entry bg-dark text-white">
                    <Link onClick={()=>history.push('/collection/scotch')}>
                    <Card.Img className='home-entry-img' src={data.image6} alt="Card image" />
                    <Card.ImgOverlay  className='storeDetails'>
                    Scotch
                    </Card.ImgOverlay>
                    </Link>
                </Card>
                <Card className="home-entry bg-dark text-white">
                    <Link onClick={()=>history.push('/collection/american')}>
                    <Card.Img className='home-entry-img' src={data.image7} alt="Card image" />
                    <Card.ImgOverlay className='storeDetails'>
                    Bourbon
                    </Card.ImgOverlay>
                    </Link>
                </Card>
            </div>
        </div>
    )
}