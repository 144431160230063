import { Form, Button } from 'react-bootstrap';
import { useState, useEffect, useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import UserContext from '../../UserContext';

export default function Login() {
  const { retrieveUserDetails, user, Toast } = useContext(UserContext);
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isActive, setIsActive] = useState(true);

  function loginUser(e) {
    e.preventDefault();

    fetch(`${process.env.BE_URL}/users/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (typeof data.accessToken !== 'undefined') {
          localStorage.setItem('token', data.accessToken);
          retrieveUserDetails(data.accessToken);

          Toast.fire({
            title: 'Login Successful',
            icon: 'success',
            text: 'Best move you did today.',
          });
          if (user.isAdmin === true) {
            history.push('/admin/products');
          } else {
            history.push('/collection');
          }
        } else {
          Toast.fire({
            title: 'Authentication failed',
            icon: 'error',
            text: 'Check your login details and try again',
          });
        }
      });
    setEmail('');
    setPassword('');
  }

  useEffect(() => {
    if (email !== '' && password !== '') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [email, password]);

  return localStorage.token === undefined ? (
    <Form className="mt-5 loginForm" onSubmit={(e) => loginUser(e)}>
      <div className="edit-title">
        <h1>Login</h1>
      </div>
      <Form.Group className="mb-3" controlId="userEmail">
        <Form.Label className="loginTitle">Login</Form.Label>
        <br />
        <Form.Label>Email address:</Form.Label>
        <Form.Control
          className="loginInput"
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="password1">
        <Form.Label>Password:</Form.Label>
        <Form.Control
          className="loginInput"
          type="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      {isActive ? (
        <Button variant="dark" type="submit" id="submitBtn">
          Login
        </Button>
      ) : (
        <Button variant="dark" type="submit" id="submitBtn" disabled>
          Login
        </Button>
      )}
    </Form>
  ) : (
    <>
      {user.isAdmin === true ? (
        <Redirect to="/admin/products" />
      ) : (
        <Redirect to="/collection" />
      )}
    </>
  );
}
