import { Fragment, useEffect, useContext } from 'react';
import { ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import { total, remove, destroy, update } from 'cart-localstorage';
import { useHistory } from 'react-router-dom';
import UserContext from '../UserContext';

export default function Cart() {
  const history = useHistory();
  const { cart, setCart, Toast } = useContext(UserContext);
  const orders = JSON.parse(localStorage.getItem('__cart'));
  const totalAmount = total();

  const checkOut = () => {
    fetch(`${process.env.BE_URL}/users/checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        products: orders,
        totalAmount: totalAmount,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          Toast.fire({
            title: 'Something went wrong',
            icon: 'error',
            text: 'Please try again.',
          });
        } else {
          Toast.fire({
            title: 'Successfully Added',
            icon: 'success',
          });
          destroy();
        }
        history.push('/collection');
      });
  };

  const cartEmpty = () => {
    Toast.fire({
      title: 'Cart Deleted',
      icon: 'error',
      text: 'Your cart is now empty. Browse our selection',
    });
    destroy();
    history.push('/collection');
  };

  const decrement = (d) => {
    d.quantity >= 2 ? update(d.id, 'quantity', d.quantity - 1) : update();
    setCart(JSON.parse(localStorage.getItem('__cart')));
  };

  const increment = (d) => {
    d.quantity >= 1
      ? update(d.id, 'quantity', Number(d.quantity) + Number(1))
      : update();
    setCart(JSON.parse(localStorage.getItem('__cart')));
  };

  const removeItem = (x) => {
    remove(x.id);
    setCart(JSON.parse(localStorage.getItem('__cart')));
  };

  useEffect(() => {}, []);

  return (
    <Fragment>
      <ListGroup className="cart-container">
        {cart.length === 0 ? (
          <div className="empty-cart">There are no items in your cart</div>
        ) : (
          <>
            <div>
              <ul className="cart-title-container">
                <li className="cart-title-name">ITEM</li>
                <li className="cart-title-qty">QTY</li>
                <li className="cart-title-price">PRICE</li>
                <li className="cart-title-total">TOTAL</li>
              </ul>
            </div>
            {cart.map(function (item, idx) {
              return (
                <ListGroupItem className="list-group-item" key={idx}>
                  <div>
                    <ul className="cart-name-container">
                      <li className="cart-detail-name">{item.name}</li>
                      <li className="cart-detail-qty">{item.quantity}</li>
                      <li className="cart-detail-price">
                        ₱ <strong>{item.price}</strong>
                      </li>
                      <li className="cart-detail-total">
                        Total: ₱ <strong>{item.price * item.quantity}</strong>
                      </li>
                    </ul>
                  </div>
                  <div className="cart-btns-container">
                    <Button
                      className="rm-btn"
                      variant="danger"
                      onClick={() => removeItem(item)}
                    >
                      x
                    </Button>
                    <div className="qty-btns">
                      <Button
                        variant="secondary"
                        onClick={() => decrement(item)}
                      >
                        -
                      </Button>
                      <div className="cart-qty">{item.quantity}</div>
                      <Button
                        variant="secondary"
                        onClick={() => increment(item)}
                      >
                        +
                      </Button>
                    </div>
                  </div>
                </ListGroupItem>
              );
            })}
          </>
        )}
      </ListGroup>
      <div className="total-container">
        {total() === 0 ? (
          <></>
        ) : (
          <>
            <span className="cart-total">Total:</span>
            <h2>₱ {total()}</h2>
          </>
        )}
      </div>
      <div className="btn-container">
        <Button
          className="rounded-pill"
          variant="danger"
          onClick={() => cartEmpty()}
        >
          Clear
        </Button>
        <Button
          className="rounded-pill"
          variant="dark"
          onClick={() => checkOut()}
        >
          Checkout
        </Button>
      </div>
    </Fragment>
  );
}
