import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';
import UserContext from '../UserContext';

export default function AdminEditProducts(props) {
  const { user, setUser, orders, myOrders, Toast } = useContext(UserContext);
  const history = useHistory();

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [mobileNo, setMobileNo] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isActive, setIsActive] = useState(false);
  const [active, setActive] = useState();

  const editButton = (x) => {
    setActive(x);
  };

  const ShowDetails = () => {
    return (
      <Form className="mt-5 account-page">
        <Row className="account-entry" controlId="firstName">
          <Col className="account-title">First Name: </Col>
          <Col className="account-details">{user.firstName}</Col>
        </Row>
        <Row className="account-entry" controlId="lastName">
          <Col className="account-title">Last Name: </Col>
          <Col className="account-details">{user.lastName}</Col>
        </Row>
        <Row className="account-entry" controlId="userEmail">
          <Col className="account-title">Registered Email: </Col>
          <Col className="account-details">{user.email}</Col>
        </Row>
        <Row className="account-entry" controlId="mobile">
          <Col className="account-title">Mobile Number: </Col>
          <Col className="account-details">{user.mobileNo}</Col>
        </Row>
        <Button
          className="account-edit-btn"
          variant="dark"
          onClick={() => editButton(true)}
        >
          Edit
        </Button>
      </Form>
    );
  };

  const EditDetails = () => {
    return (
      <Form className="mt-5 account-page">
        <Form.Group className="account-entry" controlId="firstName">
          <Col className="account-title">First Name: </Col>
          <Form.Control
            className="account-details"
            type="text"
            placeholder={user.firstName}
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="account-entry" controlId="lastName">
          <Col className="account-title">Last Name: </Col>
          <Form.Control
            className="account-details"
            type="text"
            placeholder={user.lastName}
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Group>
        <Row className="account-entry" controlId="userEmail">
          <Col className="account-title">Registered Email: </Col>
          <Col className="account-details">{user.email}</Col>
        </Row>
        <Form.Group className="account-entry" controlId="mobile">
          <Col className="account-title">Mobile Number: </Col>
          <Form.Control
            className="account-details"
            type="tel"
            placeholder={user.mobileNo}
            required
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
          />
        </Form.Group>
        {isActive ? (
          <Button
            className="account-edit-btn"
            variant="dark"
            type="submit"
            id="submitBtn"
            onClick={(e) => e}
          >
            Submit
          </Button>
        ) : (
          <Button
            className="account-edit-btn"
            variant="dark"
            type="submit"
            id="submitBtn"
            disabled
          >
            Submit
          </Button>
        )}
      </Form>
    );
  };

  const editUserDetails = () => {
    fetch(`${process.env.BE_URL}/users/${user.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        mobileNo: mobileNo,
      }),
    })
      .then((res) => res.json())
      .then((data) => {});
    if (user.id) {
      Toast.fire({
        title: 'Successfully Edited',
        icon: 'success',
      });
      history.push('/collection');
    } else {
      Toast.fire({
        title: 'Something went wrong',
        icon: 'error',
        text: 'Please try again.',
      });
    }
  };

  const retrieveUserDetails = (token) => {
    fetch(`${process.env.BE_URL}/users/details`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem('isAdmin', data.isAdmin);
        localStorage.setItem('id', data._id);
        setUser({
          id: data._id,
          isAdmin: data.isAdmin,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          mobileNo: data.mobileNo,
        });
      });
  };

  const retrieveMyOrders = () => {
    orders.map((item) => {
      if (item.userId === user.id) {
        myOrders.push(item);
      }
    });
  };

  useEffect(() => {
    retrieveUserDetails(localStorage.getItem('token'));
    retrieveMyOrders();

    if (
      firstName !== '' &&
      lastName !== '' &&
      email !== '' &&
      mobileNo !== '' &&
      password !== ''
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [firstName, lastName, email, mobileNo, password, myOrders, orders]);

  return (
    <Container>
      <div className="edit-title">
        <h1>Account Details</h1>
      </div>
      {active === true ? <EditDetails /> : <ShowDetails />}
      <div className="edit-title">
        <h1>Order History</h1>
      </div>
      <ListGroup className="order-container">
        {myOrders.length === 0 ? (
          <div className="empty-order">No orders yet</div>
        ) : (
          <>
            <div>
              <ul className="order-title-container">
                <li className="order-title-date">Order Date</li>
                <li className="order-title-status">Order Status</li>
                <li className="order-title-items">
                  <Row>
                    <Col sm={8}>Item</Col>
                    <Col>Qty</Col>
                    <Col>Amount</Col>
                  </Row>
                </li>
                <li className="order-title-total">Total Amount</li>
              </ul>
            </div>
            {myOrders.map(function (item, idx) {
              return (
                <ListGroupItem className="list-group-item" key={idx}>
                  <div>
                    <ul className="order-name-container">
                      <li className="order-detail-date">{item.orderedOn}</li>
                      <li className="order-detail-status">{item.status}</li>
                      <li className="order-detail-items">
                        {item.products.map(function (entry, idx) {
                          return (
                            <Row>
                              <Col sm={8}>{entry.name}</Col>
                              <Col>{entry.quantity}</Col>
                              <Col>{entry.price}</Col>
                            </Row>
                          );
                        })}
                      </li>
                      <li className="order-detail-total">
                        ₱ <strong>{item.totalAmount}</strong>
                      </li>
                    </ul>
                  </div>
                </ListGroupItem>
              );
            })}
          </>
        )}
      </ListGroup>
    </Container>
  );
}
