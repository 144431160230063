import { Fragment, useEffect, useState, useContext } from 'react';
import AdminProductList from '../../Components/AdminProductList';
import { Col } from 'react-bootstrap';

export default function Admin() {
  const [products, setProducts] = useState([]);
  const [productDetails, setProductDetails] = useState([]);

  const retrieveProducts = () => {
    fetch(`${process.env.BE_URL}/products/all`)
      .then((res) => res.json())
      .then((data) => {
        setProducts(data);
      });
  };

  useEffect(() => {
    retrieveProducts();
    setProductDetails(
      products.map((item) => {
        return <AdminProductList key={item._id} productProp={item} />;
      })
    );
  }, [products]);

  return (
    <Fragment>
      <Col>
        <div className="prod-list-main" id="admin-label">
          <div className="prod-list">
            <div className="admin-badge" />
            <div className="prod-list-details">Product Name</div>
            <div className="prod-list-details">Price</div>
            <div className="prod-list-details">Stocks</div>
            <div className="prod-list-details">Category</div>
          </div>
          <div className="btn" />
          <div className="admin-product-btn" />
        </div>
      </Col>
      {productDetails}
    </Fragment>
  );
}
