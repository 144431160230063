import { Badge, Col, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useContext } from 'react';
import UserContext from '../UserContext';

export default function AdminProductList({ productProp }) {
  const { Toast } = useContext(UserContext);
  const history = useHistory();
  const { _id, Name, Price, Category, Stocks, isActive } = productProp;

  const ActivateProduct = (productId) => {
    fetch(`${process.env.BE_URL}/products/${_id}/activate`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {});
    if (productId) {
      Toast.fire({
        title: 'Something went wrong',
        icon: 'error',
        text: 'Please try again.',
      });
    } else {
      Toast.fire({
        title: 'Activated',
        icon: 'success',
      });
      history.push('/admin/products');
    }
  };

  const DectivateProduct = (productId) => {
    fetch(`${process.env.BE_URL}/products/${_id}/deactivate`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {});
    if (productId) {
      Toast.fire({
        title: 'Something went wrong',
        icon: 'error',
        text: 'Please try again.',
      });
    } else {
      Toast.fire({
        title: 'Deactivated',
        icon: 'success',
      });
      history.push('/admin/products');
    }
  };

  return (
    <Col>
      <div className="prod-list-main">
        <div className="prod-list">
          {isActive ? (
            <Badge className="admin-badge" pill bg="success">
              Active
            </Badge>
          ) : (
            <Badge pill className="admin-badge" bg="secondary">
              Not Active
            </Badge>
          )}
          <div className="prod-list-details">{Name}</div>
          <div className="prod-list-details">{Price}</div>
          <div className="prod-list-details">{Stocks}</div>
          <div className="prod-list-details">{Category}</div>
        </div>
        <Link className="btn btn-dark" to={`/admin/products/${_id}`}>
          Edit
        </Link>
        {isActive !== true ? (
          <Button
            className="admin-product-btn"
            variant="success"
            type="submit"
            id="submitBtn"
            onClick={() => ActivateProduct()}
          >
            Activate
          </Button>
        ) : (
          <Button
            className="admin-product-btn"
            variant="secondary"
            type="submit"
            id="submitBtn"
            onClick={() => DectivateProduct()}
          >
            Deactivate
          </Button>
        )}
      </div>
    </Col>
  );
}
