import { useState, useEffect, useReducer, useContext } from 'react';
import {
  Card,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { add } from 'cart-localstorage';
import UserContext from '../UserContext';

export default function ProductDetails(props) {
  const { cart, Toast } = useContext(UserContext);
  const { productId } = props.match.params;
  const history = useHistory();

  const [name, setName] = useState();
  const [longDescription, setLongDescription] = useState();
  const [price, setPrice] = useState();
  const [stocks, setStocks] = useState();
  const [imageLink1, setImageLink1] = useState();
  const [isActive, setIsActive] = useState();

  const reducer = (state, action) => {
    switch (action.type) {
      case 'INCREASE':
        return { count: state.count + 1 };
      case 'DECREASE':
        return { count: state.count - 1 };
      default:
        return state;
    }
  };

  const Reducer = () => {
    const [state, dispatch] = useReducer(reducer, { count: 1 });
    localStorage.setItem('qty', state.count);
    return (
      <div className="details-qty-btns">
        <Button
          variant="secondary"
          className="cart-qty"
          onClick={() => {
            dispatch({ type: 'INCREASE' });
          }}
        >
          +
        </Button>
        <div className="cart-qty">{state.count}</div>
        <Button
          variant="secondary"
          className="cart-qty"
          onClick={() => {
            dispatch({ type: 'DECREASE' });
          }}
        >
          -
        </Button>
      </div>
    );
  };

  const itemToCart = {
    id: productId,
    name: name,
    price: price,
    image: imageLink1,
    quantity: localStorage.qty,
  };

  const addToCart = (d) => {
    add(itemToCart, localStorage.qty);
    localStorage.setItem('qty', 0);
    if (d) {
      Toast.fire({
        title: 'Something went wrong',
        icon: 'error',
        text: 'Please try again.',
      });
    } else {
      Toast.fire({
        title: 'Successfully Added',
        icon: 'success',
        showConfirmButton: false,
        timer: 1000,
      });
      history.push('/collection');
    }
  };

  useEffect(() => {
    fetch(`${process.env.BE_URL}/products/${productId}`)
      .then((res) => res.json())
      .then((data) => {
        setName(data.Name);
        setLongDescription(data.LongDescription);
        setPrice(data.Price);
        setImageLink1(data.ImageLink1);
        setStocks(data.Stocks);
        setIsActive(data.isActive);
      });
  }, [productId, cart]);

  return (
    <div className="product-view">
      <img className="img-details" src={imageLink1}></img>
      <Card className="product-details">
        <Card.Title className="product-name">{name}</Card.Title>
        <div className="product-qty">
          <Reducer />
          {localStorage.id === undefined ? (
            <>
              {['right'].map((p) => (
                <OverlayTrigger
                  key={p}
                  placement={p}
                  overlay={<Tooltip id={`tooltip-${p}`}>Please login.</Tooltip>}
                >
                  <Link class="btn btn-dark btn-block" to="/login">
                    Add to cart
                  </Link>
                </OverlayTrigger>
              ))}
            </>
          ) : (
            <Button variant="dark" onClick={() => addToCart()}>
              Add to cart
            </Button>
          )}
        </div>
        <Card.Subtitle>Description:</Card.Subtitle>
        <Card.Text>{longDescription}</Card.Text>
        <Card.Subtitle>Price: ₱ {price}</Card.Subtitle>
        <Card.Text>{stocks} stocks available</Card.Text>
        {isActive == true ? (
          <Card.Text>Available</Card.Text>
        ) : (
          <Card.Text>For Pre-Order</Card.Text>
        )}
      </Card>
    </div>
  );
}
