import { Button , Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Banner({data}){
    const {title, content, destination, label} = data;
    return (
		<Row>
			<Col className="p-5 errorPage">
				<h1>{title}</h1>
				<p>{content}</p>
				<Button as={Link} variant='dark' to={destination}>{label}</Button>
			</Col>
		</Row>
    )
}