import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';

export default function NavbarAppAdmin () {
    const logo = {
        cart: 'https://www.freeiconspng.com/thumbs/cart-icon/basket-cart-icon-27.png',
        icon: 'https://cdn-icons-png.flaticon.com/512/6284/6284888.png'
    }

    return (
        <Navbar bg="dark" expand="lg" variant='dark'>
            <Container fluid>
                <Navbar.Brand><img className='wrlogo' src={logo.icon}/></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
                    <Nav.Link href="/admin/products">Product List</Nav.Link>
                    <Nav.Link href="/admin/addproduct">Add Product</Nav.Link>
                    <Nav.Link href="/admin/orders">Orders</Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link as={NavLink} to="/logout">Logout</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
