import { Container } from 'react-bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { UserProvider } from './UserContext';
import Swal from 'sweetalert2';

// Components
import NavbarApp from './Components/Navbar';
import NavbarAppAdmin from './Components/NavbarAdmin';

// Pages
import Home from './pages/Home';
import Login from './pages/users/Login';
import Register from './pages/users/Register';
import Logout from './pages/users/Logout';
import Error from './pages/users/Error';
import Scotch from './pages/products/Scotch';
import American from './pages/products/American';
import Irish from './pages/products/Irish';
import Japan from './pages/products/Japan';
import Complete from './pages/products/Complete';
import ProductDetails from './Components/ProductDetails';
import Cart from './pages/Cart';
import Footer from './Components/Footer';

import Admin from './pages/users/Admin';
import AdminEditProducts from './Components/AdminEditProduct';
import AddProduct from './pages/products/AddProduct';
import Orders from './pages/users/Orders';
import AccountDetails from './Components/AccountDetails';
import { list } from 'cart-localstorage';

function App() {
  const history = useHistory();
  // User
  const [user, setUser] = useState({
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    mobileNo: null,
    password: null,
    isAdmin: null,
  });

  // Cart
  const [cart, setCart] = useState([]);
  const [orders, setOrders] = useState([]);
  const [myOrders, setMyOrders] = useState([]);

  // Unset User
  const unsetUser = () => {
    Swal.fire({
      title: 'Logged out',
      icon: 'success',
      text: 'See you soon!',
      showConfirmButton: false,
      timer: 1000,
    });
    setUser({
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      mobileNo: null,
      password: null,
      isAdmin: null,
    });
    localStorage.clear();
  };

  const retrieveUserDetails = (token) => {
    fetch(`${process.env.BE_URL}/users/details`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUser({
          id: data._id,
          isAdmin: data.isAdmin,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          mobileNo: data.mobileNo,
        });
        data.isAdmin === true
          ? history.push('/admin/products')
          : history.push('/collection');
      });
  };

  // Fetch user orders
  const retrieveOrders = () => {
    fetch('${process.env.BE_URL}/orders/')
      .then((res) => res.json())
      .then((data) => {
        setOrders(data);
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'center',
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  useEffect(() => {
    setCart(list());
    retrieveOrders();
    retrieveUserDetails(localStorage.token);
  }, []);

  return (
    <UserProvider
      value={{
        retrieveUserDetails,
        user,
        setUser,
        unsetUser,
        cart,
        setCart,
        orders,
        setOrders,
        myOrders,
        setMyOrders,
        retrieveOrders,
        Toast,
      }}
    >
      <div>
        {user.isAdmin !== true ? (
          <Router>
            <NavbarApp />
            <Container className="main-container">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route
                  exact
                  path="/products/:productId"
                  component={ProductDetails}
                />
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/cart" component={Cart} />
                <Route exact path="/collection/scotch" component={Scotch} />
                <Route exact path="/collection/american" component={American} />
                <Route exact path="/collection/irish" component={Irish} />
                <Route exact path="/collection/japan" component={Japan} />
                <Route exact path="/collection" component={Complete} />
                <Route exact path="/users/:userId" component={AccountDetails} />
                <Route exact path="*" component={Error} />
              </Switch>
            </Container>
          </Router>
        ) : (
          <Router>
            <NavbarAppAdmin />
            <Container className="main-container">
              <Switch>
                <Route exact path="/admin/products" component={Admin} />
                <Route
                  exact
                  path="/admin/products/:productId"
                  component={AdminEditProducts}
                />
                <Route exact path="/admin/addproduct" component={AddProduct} />
                <Route exact path="/admin/orders" component={Orders} />
              </Switch>
            </Container>
          </Router>
        )}
      </div>
      <div>
        <Footer />
      </div>
    </UserProvider>
  );
}

export default App;
