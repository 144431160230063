import { Fragment, useEffect, useContext } from 'react';
import UserContext from '../../UserContext';
import { ListGroup, ListGroupItem, Row, Col } from 'react-bootstrap';

export default function Admin() {
  const { orders, setOrders } = useContext(UserContext);

  const retrieveOrders = () => {
    fetch(`${process.env.BE_URL}/orders/`)
      .then((res) => res.json())
      .then((data) => {
        setOrders(data);
      });
  };

  useEffect(() => {
    retrieveOrders();
  }, []);

  return (
    <Fragment>
      <ListGroup className="order-container">
        {orders.length === 0 ? (
          <div className="empty-order">No orders yet</div>
        ) : (
          <>
            <div>
              <ul className="order-title-container">
                <li className="order-title-id">Customer Id</li>
                <li className="order-title-date">Order Date</li>
                <li className="order-title-status">Order Status</li>
                <li className="order-title-items">
                  <Row>
                    <Col sm={8}>Item</Col>
                    <Col>Qty</Col>
                    <Col>Amount</Col>
                  </Row>
                </li>
                <li className="order-title-total">Total Amount</li>
              </ul>
            </div>
            {orders.map(function (item, idx) {
              return (
                <ListGroupItem className="list-group-item" key={idx}>
                  <div>
                    <ul className="order-name-container">
                      <li className="order-detail-id">{item._id}</li>
                      <li className="order-detail-date">{item.orderedOn}</li>
                      <li className="order-detail-status">{item.status}</li>
                      <li className="order-detail-items">
                        {item.products.map(function (entry, idx) {
                          return (
                            <Row>
                              <Col sm={8}>{entry.name}</Col>
                              <Col>{entry.quantity}</Col>
                              <Col>{entry.price}</Col>
                            </Row>
                          );
                        })}
                      </li>
                      <li className="order-detail-total">
                        ₱ <strong>{item.totalAmount}</strong>
                      </li>
                    </ul>
                  </div>
                </ListGroupItem>
              );
            })}
          </>
        )}
      </ListGroup>
    </Fragment>
  );
}
