import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Container } from 'react-bootstrap';
import UserContext from '../UserContext';

export default function AdminEditProducts(props) {
  const { Toast } = useContext(UserContext);
  const history = useHistory();
  const { productId } = props.match.params;
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [category, setCategory] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [longDescription, setLongDescription] = useState();
  const [price, setPrice] = useState();
  const [stocks, setStocks] = useState();
  const [available, setAvailable] = useState();
  const [imageLink1, setImageLink1] = useState();
  const [imageLink2, setImageLink2] = useState();

  const editProductDetails = (productId) => {
    fetch(`${process.env.BE_URL}/products/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        Id: id,
        Category: category,
        Name: name,
        Price: price,
        ShortDescription: shortDescription,
        LongDescription: longDescription,
        ImageLink1: imageLink1,
        ImageLink2: imageLink2,
        Stocks: stocks,
        isActive: JSON.parse(available),
      }),
    })
      .then((res) => res.json())
      .then((data) => {});
    if (productId) {
      Toast.fire({
        title: 'Successfully Edited',
        icon: 'success',
        showConfirmButton: false,
        timer: 1000,
      });
      history.push('/admin/products');
    } else {
      Toast.fire({
        title: 'Something went wrong',
        icon: 'error',
        text: 'Please try again.',
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const ProductStatus = () => {
    return localStorage.isActive === 'true' ? (
      <span>Active</span>
    ) : (
      <span>Not Active</span>
    );
  };

  useEffect(() => {
    fetch(`${process.env.BE_URL}/products/${productId}`)
      .then((res) => res.json())
      .then((data) => {
        setId(data._id);
        setCategory(data.Category);
        setName(data.Name);
        setPrice(data.Price);
        setShortDescription(data.ShortDescription);
        setLongDescription(data.LongDescription);
        setImageLink1(data.ImageLink1);
        setStocks(data.Stocks);
        setAvailable(data.isActive);
        localStorage.setItem('isActive', data.isActive);
        localStorage.setItem('productId', data._id);
      });
  }, [productId]);

  return (
    <Container>
      <div className="edit-title">
        <h1>Edit Product Details</h1>
      </div>
      <Form className="admin-edit-product">
        <Form.Group className="admin-edit-input" controlId="category">
          <span>Product ID</span>
          <Form.Control
            type="text"
            placeholder={id}
            required
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="admin-edit-input" controlId="category">
          <span>Category</span>
          <Form.Control
            type="text"
            placeholder={category}
            required
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="admin-edit-input" controlId="name">
          <span>Product name</span>
          <Form.Control
            type="text"
            placeholder={name}
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="admin-edit-input" controlId="price">
          <span>Price</span>
          <Form.Control
            type="text"
            placeholder={price}
            required
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="admin-edit-input" controlId="stocks">
          <span>Stocks</span>
          <Form.Control
            type="text"
            placeholder={stocks}
            required
            value={stocks}
            onChange={(e) => setStocks(e.target.value)}
          />
        </Form.Group>
        <Button
          className="admin-edit-btn"
          variant="dark"
          type="submit"
          id="submitBtn"
          onClick={(e) => editProductDetails(e)}
        >
          Submit
        </Button>
      </Form>
    </Container>
  );
}
