import { Form, Button } from 'react-bootstrap';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../UserContext';

export default function Register() {
  const { Toast } = useContext(UserContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();

  const registerUser = (e) => {
    e.preventDefault();
    fetch(`${process.env.BE_URL}/users/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobileNo: mobile,
        password: password,
      }),
    })
      .then((res) => res.json())
      .then((success) => {});
    if (e) {
      Toast.fire({
        title: 'Registration Successful',
        icon: 'success',
      });
      history.push('/login');
    } else {
      Toast.fire({
        title: 'Email/Mobile not available',
        icon: 'error',
        text: 'Please provide different email/mobile',
      });
    }
  };

  useEffect(() => {
    if (
      firstName !== '' &&
      lastName !== '' &&
      email !== '' &&
      mobile !== '' &&
      password !== '' &&
      password2 !== '' &&
      password === password2
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [firstName, lastName, email, mobile, password, password2]);

  return (
    <Form className="mt-5">
      <div className="edit-title">
        <h1>Registration</h1>
      </div>
      <Form.Group className="mb-3" controlId="firstName">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter First Name"
          required
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="lastName">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Last Name"
          required
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="userEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="mobile">
        <Form.Label>Mobile Number</Form.Label>
        <Form.Control
          type="tel"
          placeholder="Enter mobile"
          required
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="password1">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="password2">
        <Form.Label>Verify Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          required
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
        />
      </Form.Group>
      {isActive ? (
        <Button
          variant="dark"
          type="submit"
          id="submitBtn"
          onClick={(e) => registerUser(e)}
        >
          Submit
        </Button>
      ) : (
        <Button variant="dark" type="submit" id="submitBtn" disabled>
          Submit
        </Button>
      )}
    </Form>
  );
}
