import { Fragment, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import Filter from '../../Components/Filter';
import ProductCard from '../../Components/ProductCard';

export default function Complete() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch(`${process.env.BE_URL}/products/`)
      .then((res) => res.json())
      .then((data) => {
        setProducts(
          data.map((d, idx) => {
            return <ProductCard key={idx} productProp={d} />;
          })
        );
      });
  }, []);

  return (
    <Fragment>
      <Filter />
      <Row className="product-row">{products}</Row>
    </Fragment>
  );
}
