import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Container } from 'react-bootstrap';
import UserContext from '../../UserContext';

export default function AddProduct() {
  const { Toast } = useContext(UserContext);
  const history = useHistory();
  const [name, setName] = useState();
  const [category, setCategory] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [longDescription, setLongDescription] = useState();
  const [price, setPrice] = useState();
  const [stocks, setStocks] = useState();
  const [available, setAvailable] = useState();
  const [imageLink1, setImageLink1] = useState();

  const addProduct = (e) => {
    fetch(`${process.env.BE_URL}/products/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        category: category,
        name: name,
        price: price,
        shortDescription: shortDescription,
        longDescription: longDescription,
        imageLink1: imageLink1,
        stocks: stocks,
        isActive: available,
      }),
    })
      .then((res) => res.json())
      .then((data) => {});
    if (e) {
      Toast.fire({
        title: 'Successfully Added',
        icon: 'success',
      });
      history.push('/admin/products');
    } else {
      Toast.fire({
        title: 'Something went wrong',
        icon: 'error',
        text: 'Please try again.',
      });
    }
  };
  return (
    <Container>
      <div className="edit-title">
        <h1>Add New Products</h1>
      </div>
      <Form>
        <Form.Group controlId="category">
          <span>Category</span>
          <Form.Control
            type="text"
            placeholder={category}
            required
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="name">
          <span>Name</span>
          <Form.Control
            type="text"
            placeholder={name}
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="price">
          <span>Price</span>
          <Form.Control
            type="text"
            placeholder={price}
            required
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="shortDescription">
          <span>ShortDescription</span>
          <Form.Control
            type="text"
            placeholder={shortDescription}
            required
            value={shortDescription}
            onChange={(e) => setShortDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="longDescription">
          <span>LongDescription</span>
          <Form.Control
            type="text"
            placeholder={longDescription}
            required
            value={longDescription}
            onChange={(e) => setLongDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="imageLink1">
          <span>ImageLink1</span>
          <Form.Control
            type="text"
            placeholder={imageLink1}
            required
            value={imageLink1}
            onChange={(e) => setImageLink1(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="stocks">
          <span>Stocks</span>
          <Form.Control
            type="text"
            placeholder={stocks}
            required
            value={stocks}
            onChange={(e) => setStocks(e.target.value)}
          />
        </Form.Group>
        <Button
          variant="dark"
          type="submit"
          id="submitBtn"
          onClick={(e) => addProduct(e)}
        >
          Submit
        </Button>
      </Form>
    </Container>
  );
}
