import {
  Form,
  FormControl,
  Dropdown,
  Button,
  ButtonGroup,
} from 'react-bootstrap';
import { useState, useEffect } from 'react';

export default function Filter() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const handleFilter = (e) => {
    const value = e.target.value;
    setFilteredProducts({
      ...filteredProducts,
      [e.target.name]: value,
    });
  };

  useEffect(() => {}, []);
  const [search, setSearch] = useState('');
  const productSearch = (search) => {
    fetch(`${process.env.BE_URL}/products/search`)
      .then((res) => res.json())
      .then((data) => {
        return data;
      });
  };

  return (
    <div className="filterSection">
      <Dropdown as={ButtonGroup}>
        <Button variant="dark">Filter by:</Button>
        <Dropdown.Toggle split variant="dark" id="dropdown-split-basic" />
        <Dropdown.Menu onChange={handleFilter}>
          <Dropdown.Item value="price-asc">Price (Low to High)</Dropdown.Item>
          <Dropdown.Item value="price-des">Price (High to Low)</Dropdown.Item>
          <Dropdown.Item value="name-asc">Name (Ascending)</Dropdown.Item>
          <Dropdown.Item value="name-des">Name (Descending)</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={ButtonGroup}>
        <Button variant="dark">Category:</Button>
        <Dropdown.Toggle split variant="dark" id="dropdown-split-basic" />
        <Dropdown.Menu Name="Category" onChange={handleFilter}>
          <Dropdown.Item href="/collection/scotch">Scotch</Dropdown.Item>
          <Dropdown.Item href="/collection/american">Bourbon</Dropdown.Item>
          <Dropdown.Item href="/collection/japan">
            Japanese Whiskey
          </Dropdown.Item>
          <Dropdown.Item href="/collection/irish">Irish Whiskey</Dropdown.Item>
          <Dropdown.Item href="/collection">All</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Form className="d-flex">
        <FormControl
          type="search"
          placeholder="Search"
          className="me-2"
          aria-label="Search"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button variant="outline-light" onClick={() => productSearch(search)}>
          Search
        </Button>
      </Form>
    </div>
  );
}
