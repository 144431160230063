import { Card, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image'

export default function ProductCard({productProp}){
    const { _id, Name, ImageLink1 } = productProp;
    return (
            <Card id='product-card' style={{ width: '18rem' }}><a href={`/products/${_id}`}>
                <Image variant="top" src={ImageLink1} />
                <Button className='card-btn' href={`/products/${_id}`}>{Name}</Button>
            </a></Card>
    )
}