import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Button, Badge } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import UserContext from '../UserContext';

export default function NavbarApp () {
    const { user, cart, retrieveUserDetails } = useContext(UserContext)

    const logo = {
        cart: 'https://www.freeiconspng.com/thumbs/cart-icon/basket-cart-icon-27.png',
        icon: 'https://cdn-icons-png.flaticon.com/512/6284/6284888.png'
    }

    const AccountDetails = () => {
        return (
            <Nav.Link href={`/users/${user.id}`}>Account</Nav.Link>
        )
    }

    const CartBtn = () => {
        return (
            (localStorage.token === undefined) ?
                <></>
            :
                <Button href='/cart' variant="dark"><img className='cartLogo' src={logo.cart} alt='cart-png' /><Badge className='cart-badge' pill bg="danger">{ cart.length }</Badge></Button>
        )
    }

    const LoggedIn = () => {
        return (
            (localStorage.token === undefined) ?
                <>
                <Nav.Link as={NavLink} to="/login">Login</Nav.Link>
                <Nav.Link as={NavLink} to="/register">Register</Nav.Link>
                </>
            :
                <>
                <AccountDetails />
                <Nav.Link as={NavLink} to="/logout">Logout</Nav.Link>
                </>
        )
    }

    useEffect(() => {}, [user, cart, retrieveUserDetails])

    return (
        <Navbar bg="dark" expand="lg" variant='dark'>
            <Container fluid>
                <Navbar.Brand><img className='wrlogo' src={logo.icon}/></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/collection">Collection</Nav.Link>
                </Nav>
                <Nav>
                    <CartBtn />
                    <LoggedIn />
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
